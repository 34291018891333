import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {
	App,
	translate,
	UseAppConfigProvider
} from '@onlineberatung/onlineberatung-frontend';
import { Stage } from './components/stage/Stage';
import { Route } from 'react-router-dom';
import { config, routePathNames } from 'resources/scripts/config';
import { TermsAndConditions } from './components/legalInformationLinks/TermsAndConditions';
import { Imprint } from './components/legalInformationLinks/Imprint';
import { Privacy } from './components/legalInformationLinks/Privacy';

ReactDOM.render(
	<UseAppConfigProvider>
		<App
			extraRoutes={[
				<Route key={1} path={routePathNames.termsAndConditions}>
					<TermsAndConditions />
				</Route>,
				<Route key={2} path={routePathNames.imprint}>
					<Imprint />
				</Route>,
				<Route key={3} path={routePathNames.privacy}>
					<Privacy />
				</Route>
			]}
			stageComponent={Stage}
			legalLinks={[
				{
					url: config.urls.imprint,
					label: translate('login.legal.infoText.impressum')
				},
				{
					url: config.urls.privacy,
					label: translate('login.legal.infoText.dataprotection'),
					registration: true
				},
				{
					url: routePathNames.termsAndConditions,
					label: translate('legal.termsAndConditions.label'),
					registration: true
				}
			]}
			entryPoint="/beratung/registration"
		/>
	</UseAppConfigProvider>,
	document.getElementById('appRoot')
);
